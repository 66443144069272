<template lang="">
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header">
            <b-col cols="8">
              <h2 class="mb-0">
                <i class="fas fa-list-alt"></i> รายการสั่งซื้ออย่างละเอียด
              </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-evaluate />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pageEvaluate from "./view/page.evaluate";
export default {
  name: "evaluate-index",
  data() {
    return {};
  },
  components: {
    pageEvaluate
  }
};
</script>
<style></style>
