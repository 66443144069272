<template>
    <div>
      <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8 mb-4">
            <b-dropdown id="dropdown-1" text="สถานะสั่งซื้อ" class="dropdown_input_search mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.order_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()" />
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label >
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="สถานะการจ่าย" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.payment_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox"  class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label" >{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="สถานะจัดส่ง" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.delivery_status_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="ร้านค้า" class="dropdown_input_search ml-1 mb-1" >
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.store_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }} ({{ v.filter_count }})</label >
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="channel-1" text="ช่องทาง" class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.channel_filters" :key="v.id" class="li_search">
                    <input type="checkbox" class="checkbox_search_input" v-model="v.selected" @change="searchHandler()"/>
                    <label class="form-check-label">{{ v.name }}</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <div class="row mt-4">
              <div class="col-sm-3">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_order" class="w-100" input-class="form-control" placeholder="วันที่สั่งซื้อ" :append-to-body="false"></date-picker>
              </div>
              <div class="col-sm-3">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_delivery" class="w-100" input-class="form-control" placeholder="วันที่จัดส่ง" :append-to-body="false"></date-picker>
              </div>
              <div class="col-sm-2">
                <select v-model="criteria.period_filters" class="form-control">
                  <option value="0">ไม่กำหนด</option>
                  <option value="1">เช้า</option>
                  <option value="2">บ่าย</option>
                  <option value="3">เย็น</option>
                  <option value="4">รอบค่ำ</option>
                </select>
              </div>
              <div class="col-sm-3">
                <select v-model="criteria.status_inspection" class="form-control"  @change="searchHandler()">
                  <option value="">สถานะการตรวจสอบ</option>
                  <option value="1">1 รอตรวจสอบออเดอร์</option>
                  <option value="2">2 ตรวจสอบออเดอร์แล้ว</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-4 ">
            <div class="mb-4">
            <b-input-group>
              <b-input-group-append><b-input-group-text class="input_border_radius_left_top_bt"><b-icon icon="search" /></b-input-group-text></b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น รหัสลูกค้า ชื่อลูกค้า สถานะการสั่งซื้อ สถานะการจ่าย" v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()"></b-form-input>
              <b-input-group-append><b-button  size="md" text="Button" variant="primary" @click="searchHandler()" class="btn_border_radius_right_top_bt" style="width: 86px;">ค้นหา</b-button></b-input-group-append>
            </b-input-group>
            </div>
            <div class="mb-4">
              <Select2 v-model="tag" :options="tags" :settings="{allowClear: true, multiple: true, tags: true}" placeholder="แท็ก" @change="searchHandler()"/>
            </div>
          </div>
          <!--
          <div class="col-sm-12 row mb-4">
            <div class="col-sm-8">
              <Select2Supplier :value="criteria.supplier" ref="Select2Supplier" @change="getSupplierById"></Select2Supplier>
            </div>
          </div>
          -->
          <div class="col-12">
            <hr class="mt-5 mb-5" />
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{ countFilter() }} รายการ</strong> จาก {{ paging.rowTotal | numberIntFormat }} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.order_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`code${index}`" v-if="select.selected">
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{ select.filter_count }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.payment_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`size${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.delivery_status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`color${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{ select.filter_count }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)" >
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;" ></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.store_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`color${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)" >
                  <i
                    class="fa fa-times icon-del"
                    style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"
                  ></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()" >ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>



      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center">
                  <th>รหัสการสั่งซื้อ</th>
                  <th>ชื่อร้านค้า</th>
                  <th>ชื่อลูกค้า</th>
                  <th>มูลค่า</th>
                  <th>สถานะสั่งซื้อ</th>
                  <th>สถานะการจ่าย</th>
                  <th>สถานะจัดส่ง</th>
                  <th>การตรวจสอบ</th>
                  <th>Printed</th>
                  <th style="min-width: 200px; width: 1px;">หมายเหตุ</th>
                  <th style="min-width: 100px; width: 1px;">พบปัญหา</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="`list_evaluate_orders_${index}`">
                  <td class="text-center">
                    <a class="nav-link text-underline"> {{ data.code }}</a>
                  </td>
                  <td class="text-center">{{ data.store_name }}</td>
                  <td class="text-center">{{ data.customer_name }}</td>
                  <td class="text-right">
                    {{ data.total || '' }}
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="
                          StringUtils.getIconOrderStatus(data.status_order)
                        "
                        :title="
                          StringUtils.getTextOrderStatus(data.status_order).text
                        "
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="
                          StringUtils.getIconPaymentStatus(data.status_payment)
                        "
                        :title="
                          StringUtils.getTextPaymentStatus(data.status_payment)
                            .text
                        "
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="StringUtils.getIconDeliveriesStatus(data.status_delivery)"
                        :title="StringUtils.getTextDeliveriesStatus(data.status_delivery).text"
                      ></i>

                      
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="
                          StringUtils.getIconInspectionStatus(
                            data.status_inspection
                          )
                        "
                        :title="
                          StringUtils.getTextInspectionStatus(
                            data.status_inspection
                          ).text
                        "
                        @click="onInspectionHandler(data)"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="
                          StringUtils.getIconPrintStatus(data.status_print)
                        "
                        :title="
                          StringUtils.getTextPrintStatus(data.status_print).text
                        "
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">{{ data.remark }}</td>
                  <td class="text-center">
                    <span class="style_icon_btn" v-if="data.issue">
                      <i
                        class="fas fa-exclamation-circle text-danger"
                        :title="data.issue ? data.issue.topic : ''"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-list"
                        @click="onViewHandler(data.id)"
                        title="รายละเอียด"
                      ></i>
                    </span>
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-portrait"
                        @click="
                          onViewManagerHandler(
                            data.id,
                            data.payment_id,
                            data.delivery_id
                          )
                        "
                        title="ผู้จัดการ"
                      ></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="12">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-4 mt-4 custom-card">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-3">
              <h3>สถานะการสั่งซื้อ</h3>
              <div
                v-for="(v, index) in order_status"
                :key="`list_order_status_${index}`"
              >
                <i :class="StringUtils.getIconOrderStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-3">
              <h3>สถานะการจ่าย</h3>
              <div
                v-for="(v, index) in payment_status"
                :key="`list_payment_status_${index}`"
              >
                <i :class="StringUtils.getIconPaymentStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-2">
              <h3>สถานะจัดส่ง</h3>
              <div v-for="(v, index) in delivery_status" :key="`list_delivery_status_${index}`">
                <i :class="StringUtils.getIconDeliveriesStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-2">
              <h3>การตรวจสอบ</h3>
              <div
                v-for="(v, index) in inspections_status"
                :key="`list_inspections_status_${index}`"
              >
                <i :class="StringUtils.getIconInspectionStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
            <div class="col-md-2">
              <h3>สถานะ Print</h3>
              <div
                v-for="(v, index) in print_status"
                :key="`list_print_status_${index}`"
              >
                <i :class="StringUtils.getIconPrintStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
          <select
            class="form-control mb-2"
            id="pageLimit"
            style="width: 80px;"
            v-model="paging.limit"
            @change="changeLimit()"
          >
            <option
              v-for="data in paging.pages"
              :key="`paging_${data}`"
              :value="data"
            >
              {{ data }}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3" v-if="paging.rowTotal > 0">
            <div
              class="col-xl-11 col-lg-10 mb-2 text-right"
              style="margin-top: 30px;"
            >
              <label
                class="form-label"
                style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;"
              >
                แสดงรายการที่
                <strong
                  >{{ (paging.page - 1) * paging.limit + 1 }} ถึง
                  {{ paging.page * paging.limit }}</strong
                >
                จาก
                <strong>{{ paging.rowTotal | numberIntFormat }} รายการ </strong>
              </label>
              <paginate
                v-model="paging.page"
                :first-last-button="true"
                :page-count="paging.pageTotal"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'&lt;'"
                :next-text="'&gt;'"
                :first-button-text="'&verbar;&lt;'"
                :last-button-text="'&gt;&verbar;'"
                :click-handler="changePage"
                :container-class="
                  'pagination pagination-sm float-right m-auto paging_ul'
                "
                :page-class="'page-link'"
                :prev-class="'page-link prev_paging'"
                :next-class="'page-link next_paging'"
              >
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label
                class="form-label"
                style="font-size: 12px;
                      font-family: Sarabun-Light;"
              >
                Go to page
              </label>
              <Select2
                v-model="paging.page"
                :options="paging.pageSizes"
                @change="changePage(paging.page)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
import Config from "../../../util/config";
import StringUtils from "../../../util/StringUtils";
import Constants from "../../../util/constants";
import mock from "../mock/evaluate";
import $ from "jquery";
import DateUtils from "../../../util/dateUtils";
export default {
  name: "evaluate-view-page-evaluate",
  data() {
    return {
      permission: {
        appSlug: "evaluate",
        actionData: {}
      },
      criteria: {
        supplier : "",
        search: "",
        orderBy: "",
        ascDesc: "",
        order_status_filters: [],
        payment_status_filters: [],
        delivery_status_filters: [],
        store_filters: [],
        channel_filters: [],
        tag_filters: [],
        dateFrom: "",
        dateTo: "",
        date_order: "",
        date_delivery: "",
        period_filters: "0",
        status_inspection: ""
      },
      StringUtils: StringUtils,
      order_status: Constants.order_status,
      payment_status: Constants.payment_status,
      delivery_status: Constants.deliveries_status,
      inspections_status: Constants.inspections_status,
      print_status: Constants.print_status,
      items: [],
      tags: [],
      tag: [],
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      mock: mock
    };
  },
  methods: {
    onViewHandler(id) {
      this.SessionStorageUtils.setSession("page-evaluate", id);
      this.$router.push(`/evaluate/order_detail/${id}`);
    },
    onViewManagerHandler(id, payment_id, delivery_id) {
      console.log(id, payment_id, delivery_id);
      this.SessionStorageUtils.setSession("page-evaluate-id", id);
      this.SessionStorageUtils.setSession(
        "page-evaluate-payment-id",
        payment_id
      );

      this.SessionStorageUtils.setSession(
        "page-evaluate-delivery-id",
        delivery_id
      );

      this.$router.push(`/evaluate/manager/${id}/${payment_id}/${delivery_id}`);
    },

    changeLimit() {
      this.paging.page = 1;
      this.getListOrder();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListOrder();
    },
    getTrMainList() {
      $(`tr.main-list`).html();
    },
    searchHandler() {
      this.paging.page = 1;
      this.saveFilter();
      this.getListOrder();
    },
    saveFilter() {
      this.SessionStorageUtils.setSession("page-order-filter",JSON.stringify(this.criteria));
    },
    async loadFilter() {
      let loader = JSON.parse(await this.SessionStorageUtils.getSession("page-order-filter"));
      console.log(loader);
      this.criteria = loader;
    },
    async getListOrder() {
      this.SpinnerService.showLoader();


//this.SessionStorageUtils.setSession("page-order-criteria", this.criteria);

      //Filter : Order Status
      let filters = [];
      this.criteria.order_status_filters.map(v => {if (v.selected) {filters.push(v.id);}});
      filters = filters.join(",");

      //Filter : Payment Status
      let paymentFilters = [];
      this.criteria.payment_status_filters.map(v => {if (v.selected) {paymentFilters.push(v.id);}});
      paymentFilters = paymentFilters.join(",");

      //Filter : Delivery Status
      let deliveryFilters = [];
      this.criteria.delivery_status_filters.map(v => {if (v.selected) {deliveryFilters.push(v.id); }});
      deliveryFilters = deliveryFilters.join(",");

      //Filter : Store Status
      let storeFilters = [];
      this.criteria.store_filters.map(v => {if (v.selected) {storeFilters.push(v.id);}});
      storeFilters = storeFilters.join(",");

      //Filter : Channel Status
      let channelFilters = [];
      this.criteria.channel_filters.map(v => {if (v.selected) {channelFilters.push(v.id);}});
      channelFilters = channelFilters.join(",");


      
      let tagFilters = [];
      if(this.tag.length>0){
        for(const v of this.tag){
          tagFilters.push(v);
        }
      }
      tagFilters = tagFilters.join(",");

      this.SpinnerService.showLoader();

      //เพิ่ม Filter วันสั่งและวันส่ง
      let date_order = "";
      let date_delivery = "";
      if (this.criteria.date_order)
        date_order = DateUtils.dateFormat(this.criteria.date_order,"YYYY-MM-DD");
      if (this.criteria.date_delivery)
        date_delivery = DateUtils.dateFormat(this.criteria.date_delivery,"YYYY-MM-DD");
      let dateFrom = "";
      let dateTo = "";
      if (this.criteria.dateFrom) {dateFrom = DateUtils.dateFormat(this.criteria.dateFrom, "YYYY-MM-DD");}
      if (this.criteria.dateTo) {dateTo = DateUtils.dateFormat(this.criteria.dateTo, "YYYY-MM-DD");}



      let params = `search=${this.criteria.search}&orderStatusFilters=${filters}&paymentStatusFilters=${paymentFilters}&deliveryStatusFilters=${deliveryFilters}`;
      params += `&storeFilters=${storeFilters}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      params += `&date_order=${date_order}&date_delivery=${date_delivery}`;
      params += `&channelFilters=${channelFilters}`;
      params += `&tagFilters=${tagFilters}`;
      params += `&periodFilters=${this.criteria.period_filters}`;

      params += `&supplier_id=${this.criteria.supplier}`;
      params += `&status_inspection=${this.criteria.status_inspection}`;



      const result = await this.HttpServices.getData(`/ab_evaluate?${params}`);
      if (result && result.status.code == "200") {
        const data = result.data;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.pageTotal = total;
        this.paging.rowTotal = data.rowTotal;
        this.items = data.data;
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
      $(`tr.main-list`).removeClass("highlight");
      this.SpinnerService.hideLoader();
    },
    countFilter() {
      const filters = this.criteria.order_status_filters.filter(v => v.selected);
      const filters1 = this.criteria.payment_status_filters.filter(v => v.selected);
      const filters2 = this.criteria.delivery_status_filters.filter(v => v.selected);
      const filtersStore = this.criteria.store_filters.filter(v => v.selected);
      return (filters.length + filters1.length + filters2.length + filtersStore.length);
    },
    unSelectFilter(item) {
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter() {
      this.criteria.order_status_filters.map(v => {v.selected = false;});
      this.criteria.payment_status_filters.map(v => {v.selected = false;});
      this.criteria.delivery_status_filters.map(v => {v.selected = false;});
      this.criteria.store_filters.map(v => {v.selected = false;});
      this.searchHandler();
    },
    async getFilterChannel() {
      const result = await this.HttpServices.getData(
        `/ab_order/getAllChannels`
      );

      const data = result.data;

      this.criteria.channel_filters = data;
    },
    async getFilterGroups() {
      const result = await this.HttpServices.getData(
        `/ab_order/getCountFilter`
      );
      const orders = result.data.orderStatus;
      const payments = result.data.paymentStatus;
      const deliveries = result.data.deliveryStatus;


      this.criteria.order_status_filters = [];
      this.criteria.payment_status_filters = [];
      this.criteria.delivery_status_filters = [];
      this.order_status.forEach(v => {
        let lst = null;
        orders.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.order_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
      this.payment_status.forEach(v => {
        let lst = null;
        payments.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.payment_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
      this.delivery_status.forEach(v => {
        let lst = null;
        deliveries.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.delivery_status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });

      this.criteria.store_filters = result.data.store;

      this.tags = await this.HttpServices.getMasterData("/master/getTags");
    }
  },
  components: {},
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.SessionStorageUtils.deleteSession("page-evaluate");
    this.getTrMainList();
    await this.getFilterGroups();
    await this.getFilterChannel();
    await this.getListOrder();
  }
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}

.dropdown_input_search > .dropdown-toggle {
  min-width: 200px;
}
.hover_pointer:hover {
  cursor: pointer;
}

li.li_search{
  padding: .5rem 1rem;
  font-size:0.875rem;
  width: 260px;
}
</style>
