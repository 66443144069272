export default {
  evaluate: {
    rows: [{
      id: 1,
      order_code: 'O-0001',
      customer_id: 1,
      customer_name: 'นายทรง ยันลือ',
      created: '',
      updated: '',
      order_date: '02/04/2021',
      status_order: 0,
      status_payment: 1,
      status_delivery: 1,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 1,
      issue_type: 1,
      status_inspection: 1,
      issue_name: 'ปัญหาภายนอก',
      store_name: 'หรีด ณ วัด',
      status_order_name: 'เปิดการขาย',
      status_payment_name: 'รอชำระเงิน',
      status_delivery_name: 'รอรูปจัดเสร็จ',
      sales_name: 'จำปา',
      issue_type_name: 'ปัญหาจากซัพพลายเออร์',
      products: [{
        id: 1,
        parent: 1,
        child: '',
        product_id: 1,
        product_code: 'B124',
        product_name: 'บุษปวัน',
        price: '300',
        upsale: true,
        total: '400',
        seq: '1',
        rowspan: 2
      }, {
        id: 2,
        parent: 1,
        child: 1,
        product_id: 2,
        product_code: 'A215',
        product_name: 'กุหลาบ',
        price: '100',
        upsale: true,
        total: '',
        seq: '1.1',
        rowspan: ''
      }, {
        id:3,
        parent: 2,
        child: '',
        product_id: 3,
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        price: '300',
        upsale: false,
        total: '300',
        seq: '2',
        rowspan: ''
      }],
      service: 100,
      amount: 700,
      total: 800,
      // user_created: 'นางสร้าง',
      // user_edit: 'นางแก้ไข',
      // user_approved: 'นางอนุมัติ',
      // remark: '',


      
      customer: {
        contact: 'contact 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        address: 'address 1',
        name: 'name 1',
        company_name: 'company_name 1',
        tax_id: 'tax_id 1',
        billing_phone: 'billing_phone 1',
        billing_address: 'billing_address 1',
        remark: 'remark 1',
        customer_type: 1,
        customer_type_name: 'นิติบุคคล',
        credit_status: 0,
        credit_status_name: 'ไม่มี',
        credit_line: 0
      },
      store: {
        name: 'name 1',
        website: 'website 1',
        phone: 'phone 1',
        email: 'email 1',
        address: 'address 1',
        name_address: 'name_address 1',
        tax_id: 'tax_id 1',
        facebook: 'facebook 1',
        line: 'line 1',
        instagram: 'instagram 1',
        twitter: 'twitter 1',
        youtube: 'youtube 1'
      },
      // order: {
      //   order_code: 'O-0001',
      //   order_date: '02/04/2021',
      //   remark: 'remark 1',
      //   products: [],
      //   total: 'total 1',
      //   service: 100,
      //   amount: 700,
      //   total: 800,
      //   sales_id: 1,
      // },
      channel: {
        name: 'name 1'
      },
      payment: {
        id: 'P-000000017',
        type: 1,
        type_name: 'โอนเงิน',
        ref: 'ref 1',
        total: 200,
        status: 2,
        status_name: 'ตรวจสอบการชำระเงินแล้ว',
        amount: 200,
        paid: 100,
        remain: 100
      },
      delivery: {
        status: 0,
        status_name: 'Draft สร้างเพื่อเก็บข้อมูล',
        expected_delivery_date_short: '02/11/2021',
        expected_delivery_time_short: '10:00',
        actual_delivery_date: '',
        actual_delivery_time: '',
        comment: 'comment 1',
        remark: 'remark 1'
      },
      issue: {
        issue_type_id: 1,
        issue_type_name: 'ปัญหาภายนอก',
        topic: 'topic 1',
        priority: 0,
        priority_name: 'ปกติ',
        remark: 'remark 1'
      },
      supplier: {
        name: 'name 1',
        tax_id: 'tax_id 1',
        tier: 'tier 1',
        tax_address: 'tax_address 1',
        contactArr: 'contactArr 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        remark: 'remark 1',
        count_day: 2,
        count_month: 2,
        price: 100,
        delivery_price: 150,
        order_amount_per_day: 5,
        order_amount_per_month: 5
      },
      managers: [{
        user_id: 1,
        user_name: 'นางสร้าง',
        action: 'สร้าง',
        created: '12/05/2020',
        updated: '12/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางแก้ไข',
        action: 'แก้ไข',
        created: '12/05/2020',
        updated: '13/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางอนุมัติ',
        action: 'อนุมัติ',
        created: '12/05/2020',
        updated: '14/05/2020',
        remark: ''
      }] 
    }, {
      id: 2,
      order_code: 'O-0002',
      customer_id: 2,
      customer_name: 'นายสัญญา ยันลือ',
      created: '',
      updated: '',
      order_date: '02/04/2021',
      status_order: 1,
      status_payment: 1,
      status_delivery: 3,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทอง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 2,
      issue_type: 3,
      status_inspection: 1,
      issue_name: 'ปัญหาจากลูกค้า',
      store_name: 'หรีด ณ วัด',
      status_order_name: 'ยืนยันการขาย',
      status_payment_name: 'รอชำระเงิน',
      status_delivery_name: 'รอรูปผู้รับ',
      sales_name: 'จำปา',
      issue_type_name: 'ปัญหาจากซัพพลายเออร์',
      products: [{
        id: 1,
        parent: 1,
        child: '',
        product_id: 1,
        product_code: 'B124',
        product_name: 'บุษปวัน',
        price: '300',
        upsale: true,
        total: '400',
        seq: '1',
        rowspan: 2
      }, {
        id: 2,
        parent: 1,
        child: 1,
        product_id: 2,
        product_code: 'A215',
        product_name: 'กุหลาบ',
        price: '100',
        upsale: true,
        total: '',
        seq: '1.1',
        rowspan: ''
      }, {
        id:3,
        parent: 2,
        child: '',
        product_id: 3,
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        price: '300',
        upsale: false,
        total: '300',
        seq: '2',
        rowspan: ''
      }],
      service: 100,
      amount: 700,
      total: 800,
      // user_created: 'นางสร้าง',
      // user_edit: 'นางแก้ไข',
      // user_approved: 'นางอนุมัติ',
      // remark: '',


      
      customer: {
        contact: 'contact 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        address: 'address 1',
        name: 'name 1',
        company_name: 'company_name 1',
        tax_id: 'tax_id 1',
        billing_phone: 'billing_phone 1',
        billing_address: 'billing_address 1',
        remark: 'remark 1',
        customer_type: 1,
        customer_type_name: 'นิติบุคคล',
        credit_status: 0,
        credit_status_name: 'ไม่มี',
        credit_line: 0
      },
      store: {
        name: 'name 1',
        website: 'website 1',
        phone: 'phone 1',
        email: 'email 1',
        address: 'address 1',
        name_address: 'name_address 1',
        tax_id: 'tax_id 1',
        facebook: 'facebook 1',
        line: 'line 1',
        instagram: 'instagram 1',
        twitter: 'twitter 1',
        youtube: 'youtube 1'
      },
      // order: {
      //   order_code: 'O-0001',
      //   order_date: '02/04/2021',
      //   remark: 'remark 1',
      //   products: [],
      //   total: 'total 1',
      //   service: 100,
      //   amount: 700,
      //   total: 800,
      //   sales_id: 1,
      // },
      channel: {
        name: 'name 1'
      },
      payment: {
        id: 'P-000000017',
        type: 1,
        type_name: 'โอนเงิน',
        ref: 'ref 1',
        total: 200,
        status: 2,
        status_name: 'ตรวจสอบการชำระเงินแล้ว',
        amount: 200,
        paid: 100,
        remain: 100
      },
      delivery: {
        status: 0,
        status_name: 'Draft สร้างเพื่อเก็บข้อมูล',
        expected_delivery_date_short: '02/11/2021',
        expected_delivery_time_short: '10:00',
        actual_delivery_date: '',
        actual_delivery_time: '',
        comment: 'comment 1',
        remark: 'remark 1'
      },
      issue: {
        issue_type_id: 1,
        issue_type_name: 'ปัญหาภายนอก',
        topic: 'topic 1',
        priority: 0,
        priority_name: 'ปกติ',
        remark: 'remark 1'
      },
      supplier: {
        name: 'name 1',
        tax_id: 'tax_id 1',
        tier: 'tier 1',
        tax_address: 'tax_address 1',
        contactArr: 'contactArr 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        remark: 'remark 1',
        count_day: 2,
        count_month: 2,
        price: 100,
        delivery_price: 150,
        order_amount_per_day: 5,
        order_amount_per_month: 5
      },
      managers: [{
        user_id: 1,
        user_name: 'นางสร้าง',
        action: 'สร้าง',
        created: '12/05/2020',
        updated: '12/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางแก้ไข',
        action: 'แก้ไข',
        created: '12/05/2020',
        updated: '13/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางอนุมัติ',
        action: 'อนุมัติ',
        created: '12/05/2020',
        updated: '14/05/2020',
        remark: ''
      }] 
    }, {
      id: 3,
      order_code: 'O-0003',
      customer_id: 3,
      customer_name: 'นายมั่นคง ยันลือ',
      created: '',
      updated: '',
      order_date: '02/04/2021',
      status_order: 2,
      status_payment: 3,
      status_delivery: 4,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: 3,
      issue_type: 4,
      status_inspection: 1,
      issue_name: 'ปัญหาจากซัพพลายเออร์',
      store_name: 'หรีด ณ วัด',
      status_order_name: 'Approved',
      status_payment_name: 'ยืนยันการชำระเงินแล้ว',
      status_delivery_name: 'ส่งรูปผู้รับแล้ว',
      sales_name: 'จำปา',
      issue_type_name: 'ปัญหาจากซัพพลายเออร์',
      products: [{
        id: 1,
        parent: 1,
        child: '',
        product_id: 1,
        product_code: 'B124',
        product_name: 'บุษปวัน',
        price: '300',
        upsale: true,
        total: '400',
        seq: '1',
        rowspan: 2
      }, {
        id: 2,
        parent: 1,
        child: 1,
        product_id: 2,
        product_code: 'A215',
        product_name: 'กุหลาบ',
        price: '100',
        upsale: true,
        total: '',
        seq: '1.1',
        rowspan: ''
      }, {
        id:3,
        parent: 2,
        child: '',
        product_id: 3,
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        price: '300',
        upsale: false,
        total: '300',
        seq: '2',
        rowspan: ''
      }],
      service: 100,
      amount: 700,
      total: 800,
      // user_created: 'นางสร้าง',
      // user_edit: 'นางแก้ไข',
      // user_approved: 'นางอนุมัติ',
      // remark: '',


      
      customer: {
        contact: 'contact 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        address: 'address 1',
        name: 'name 1',
        company_name: 'company_name 1',
        tax_id: 'tax_id 1',
        billing_phone: 'billing_phone 1',
        billing_address: 'billing_address 1',
        remark: 'remark 1',
        customer_type: 1,
        customer_type_name: 'นิติบุคคล',
        credit_status: 0,
        credit_status_name: 'ไม่มี',
        credit_line: 0
      },
      store: {
        name: 'name 1',
        website: 'website 1',
        phone: 'phone 1',
        email: 'email 1',
        address: 'address 1',
        name_address: 'name_address 1',
        tax_id: 'tax_id 1',
        facebook: 'facebook 1',
        line: 'line 1',
        instagram: 'instagram 1',
        twitter: 'twitter 1',
        youtube: 'youtube 1'
      },
      // order: {
      //   order_code: 'O-0001',
      //   order_date: '02/04/2021',
      //   remark: 'remark 1',
      //   products: [],
      //   total: 'total 1',
      //   service: 100,
      //   amount: 700,
      //   total: 800,
      //   sales_id: 1,
      // },
      channel: {
        name: 'name 1'
      },
      payment: {
        id: 'P-000000017',
        type: 1,
        type_name: 'โอนเงิน',
        ref: 'ref 1',
        total: 200,
        status: 2,
        status_name: 'ตรวจสอบการชำระเงินแล้ว',
        amount: 200,
        paid: 100,
        remain: 100
      },
      delivery: {
        status: 0,
        status_name: 'Draft สร้างเพื่อเก็บข้อมูล',
        expected_delivery_date_short: '02/11/2021',
        expected_delivery_time_short: '10:00',
        actual_delivery_date: '',
        actual_delivery_time: '',
        comment: 'comment 1',
        remark: 'remark 1'
      },
      issue: {
        issue_type_id: 1,
        issue_type_name: 'ปัญหาภายนอก',
        topic: 'topic 1',
        priority: 0,
        priority_name: 'ปกติ',
        remark: 'remark 1'
      },
      supplier: {
        name: 'name 1',
        tax_id: 'tax_id 1',
        tier: 'tier 1',
        tax_address: 'tax_address 1',
        contactArr: 'contactArr 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        remark: 'remark 1',
        count_day: 2,
        count_month: 2,
        price: 100,
        delivery_price: 150,
        order_amount_per_day: 5,
        order_amount_per_month: 5
      },
      managers: [{
        user_id: 1,
        user_name: 'นางสร้าง',
        action: 'สร้าง',
        created: '12/05/2020',
        updated: '12/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางแก้ไข',
        action: 'แก้ไข',
        created: '12/05/2020',
        updated: '13/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางอนุมัติ',
        action: 'อนุมัติ',
        created: '12/05/2020',
        updated: '14/05/2020',
        remark: ''
      }] 
    }, {
      id: 4,
      order_code: 'O-0004',
      customer_id: 4,
      customer_name: 'นางดอกไม้ ยันลือ',
      created: '',
      updated: '',
      order_date: '02/04/2021',
      status_order: 0,
      status_payment: 2,
      status_delivery: 1,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: '',
      issue_type: 2,
      status_inspection: 3,
      issue_name: 'ปัญหาภายใน',
      store_name: 'หรีด ณ วัด',
      status_order_name: 'เปิดการขาย',
      status_payment_name: 'ชำระเงินแล้ว',
      status_delivery_name: 'รอรูปจัดเสร็จ',
      sales_name: 'จำปา',
      issue_type_name: 'ปัญหาจากซัพพลายเออร์',
      products: [{
        id: 1,
        parent: 1,
        child: '',
        product_id: 1,
        product_code: 'B124',
        product_name: 'บุษปวัน',
        price: '300',
        upsale: true,
        total: '400',
        seq: '1',
        rowspan: 2
      }, {
        id: 2,
        parent: 1,
        child: 1,
        product_id: 2,
        product_code: 'A215',
        product_name: 'กุหลาบ',
        price: '100',
        upsale: true,
        total: '',
        seq: '1.1',
        rowspan: ''
      }, {
        id:3,
        parent: 2,
        child: '',
        product_id: 3,
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        price: '300',
        upsale: false,
        total: '300',
        seq: '2',
        rowspan: ''
      }],
      service: 100,
      amount: 700,
      total: 800,
      user_created: 'นางสร้าง',
      user_edit: 'นางแก้ไข',
      user_approved: 'นางอนุมัติ',
      remark: ''
    }, {
      id: 5,
      order_code: 'O-0004',
      customer_id: 5,
      customer_name: 'นางกลอย ยันลือ',
      created: '',
      updated: '',
      order_date: '02/04/2021',
      status_order: 0,
      status_payment: 1,
      status_delivery: 2,
      remark: '',
      total: '1450.00',
      cost_product: '2500.00',
      cost_shipping: '2500.00',
      cost_discount: '10',
      cost_extra: '200',
      price_product: '2500.00',
      price_shipping: '2500.00',
      price_extra: '2500.00',
      user_id: 1,
      sales_id: 1,
      delivery_phone: '0909090909',
      delivery_contact: 'นายทองแดง',
      delivery_address: '12121/2323 ชั้น 2',
      delivery_location: 'พระโขนง',
      store_id: 1,
      status_refund: 0,
      price_refund: '',
      channel_id: '',
      issue_type: 1,
      status_inspection: 2,
      issue_name: 'ปัญหาภายนอก',
      store_name: 'หรีด ณ วัด',
      status_order_name: 'เปิดการขาย',
      status_payment_name: 'รอชำระเงิน',
      status_delivery_name: 'ส่งรูปจัดเสร็จแล้ว',
      sales_name: 'จำปา',
      issue_type_name: 'ปัญหาจากซัพพลายเออร์',
      products: [{
        id: 1,
        parent: 1,
        child: '',
        product_id: 1,
        product_code: 'B124',
        product_name: 'บุษปวัน',
        price: '300',
        upsale: true,
        total: '400',
        seq: '1',
        rowspan: 2
      }, {
        id: 2,
        parent: 1,
        child: 1,
        product_id: 2,
        product_code: 'A215',
        product_name: 'กุหลาบ',
        price: '100',
        upsale: true,
        total: '',
        seq: '1.1',
        rowspan: ''
      }, {
        id:3,
        parent: 2,
        child: '',
        product_id: 3,
        product_code: 'A0XX',
        product_name: 'พวงหรีดดอกไม้สด',
        price: '300',
        upsale: false,
        total: '300',
        seq: '2',
        rowspan: ''
      }],
      service: 100,
      amount: 700,
      total: 800,
      // user_created: 'นางสร้าง',
      // user_edit: 'นางแก้ไข',
      // user_approved: 'นางอนุมัติ',
      // remark: '',


      
      customer: {
        contact: 'contact 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        address: 'address 1',
        name: 'name 1',
        company_name: 'company_name 1',
        tax_id: 'tax_id 1',
        billing_phone: 'billing_phone 1',
        billing_address: 'billing_address 1',
        remark: 'remark 1',
        customer_type: 1,
        customer_type_name: 'นิติบุคคล',
        credit_status: 0,
        credit_status_name: 'ไม่มี',
        credit_line: 0
      },
      store: {
        name: 'name 1',
        website: 'website 1',
        phone: 'phone 1',
        email: 'email 1',
        address: 'address 1',
        name_address: 'name_address 1',
        tax_id: 'tax_id 1',
        facebook: 'facebook 1',
        line: 'line 1',
        instagram: 'instagram 1',
        twitter: 'twitter 1',
        youtube: 'youtube 1'
      },
      // order: {
      //   order_code: 'O-0001',
      //   order_date: '02/04/2021',
      //   remark: 'remark 1',
      //   products: [],
      //   total: 'total 1',
      //   service: 100,
      //   amount: 700,
      //   total: 800,
      //   sales_id: 1,
      // },
      channel: {
        name: 'name 1'
      },
      payment: {
        id: 'P-000000017',
        type: 1,
        type_name: 'โอนเงิน',
        ref: 'ref 1',
        total: 200,
        status: 2,
        status_name: 'ตรวจสอบการชำระเงินแล้ว',
        amount: 200,
        paid: 100,
        remain: 100
      },
      delivery: {
        status: 0,
        status_name: 'Draft สร้างเพื่อเก็บข้อมูล',
        expected_delivery_date_short: '02/11/2021',
        expected_delivery_time_short: '10:00',
        actual_delivery_date: '',
        actual_delivery_time: '',
        comment: 'comment 1',
        remark: 'remark 1'
      },
      issue: {
        issue_type_id: 1,
        issue_type_name: 'ปัญหาภายนอก',
        topic: 'topic 1',
        priority: 0,
        priority_name: 'ปกติ',
        remark: 'remark 1'
      },
      supplier: {
        name: 'name 1',
        tax_id: 'tax_id 1',
        tier: 'tier 1',
        tax_address: 'tax_address 1',
        contactArr: 'contactArr 1',
        phone: 'phone 1',
        email: 'email 1',
        line: 'line 1',
        remark: 'remark 1',
        count_day: 2,
        count_month: 2,
        price: 100,
        delivery_price: 150,
        order_amount_per_day: 5,
        order_amount_per_month: 5
      },
      managers: [{
        user_id: 1,
        user_name: 'นางสร้าง',
        action: 'สร้าง',
        created: '12/05/2020',
        updated: '12/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางแก้ไข',
        action: 'แก้ไข',
        created: '12/05/2020',
        updated: '13/05/2020',
        remark: ''
      }, {
        user_id: 1,
        user_name: 'นางอนุมัติ',
        action: 'อนุมัติ',
        created: '12/05/2020',
        updated: '14/05/2020',
        remark: ''
      }] 
    }],
    paging: {
      rowTotal: 1
    }
  }
}
